
import FaqItem from "./faqItemMainPage";
export default {
  name: "faq",
  components: {
    FaqItem,
  },
  data() {
    return {
      faqs: [
        {
          question:
            "This is free?",
          answer:
            `There is no cost to register or to browse the site. There is a <a href = "https://ifindcheaters.com/products/find-someone-cheating-online-for-free/"><strong>FREE TRIAL</strong></a> available for either selected <strong>DATING/ALTERNATIVE LIFESTYLE SITES</strong> or selected <strong>SOCIAL MEDIA & MOBILE CHAT/HOOK-UP APPS</strong>. If you have requested a search using your partner’s name and email address, to access the complete results you will need to purchase one of the individual search credit options or a package plan. The technical maintenance and constant research and development required to keep our service current is a costly process, but we try to keep our prices as reasonable as possible. Every report is also downloadable as a PDF document.`,
          open: false,
        },
        {
          question:
            "How do I get free report?",
          answer:
            "The <strong>FREE TRIAL</strong> runs a scan on selected services chosen by our tech team so you can see how it works in real time. To receive a full report requires the purchase of search credits for the online platforms or apps you want to scan.",
          open: false,
        },
        {
          question:
            "How will this charge appear on my bank or credit card statement?",
          answer:
            "When you use your credit or debit card the charge will appear as iFC Search Products on your monthly statement.",
          open: false,
        },
        {
          question: "How do I find out if my partner is cheating?",
          answer:
            "iFindCheaters offers a variety of services to help you discover if your partner is active online. Once you know which sites or apps they are users of, then you can do more research. You may want to start by verifying that the information they have given you is genuine by using our <strong>LISTING LOCATOR</strong> service.",
          open: false,
        },
        {
          question: "Can I check by entering a name only?",
          answer:
            "Most online sites/apps require users to enter a name and email address or mobile phone number. They may not be using their given name, maybe a nickname instead, having an email address increases the search power. Our <strong>LISTING LOCATOR</strong> can help you find other phone numbers or email addresses your partner might be using.",
          open: false,
        },
        {
          question:
            "DATING/ALTERNATIVE LIFESTYLE SITES or SOCIAL MEDIA & MOBILE CHAT/HOOK-UP APPS",
          answer:
            "<strong>FLEX</strong> – This option allows you to purchase as many search credits as you think you will need and use them individually to generate searches with any name or email address you choose.<br><br>  <strong>MONTHLY</strong> – This option runs a daily scan using one or more email addresses. You can request notification of results daily, or only when new activity is detected. Monthly plans have recurring billing. If you cancel the plan reports will continue to be generated until the end of that billing cycle but there will be no further charges.<br><br>  <strong>ULTIMATE</strong> – This option allows you to create the combination that works best for your needs. You can have some emails scanned daily and have individual credit bundles for single searches whenever you want them. Ultimate plans have recurring billing and any search credits not used the previous month carry over until used, they never expire.",
          open: false,
        },
        {
          question: "LISTING LOCATOR",
          answer:"This service enables you to get information to verify identity, relationship status, physical addresses, telephone numbers and email. You can search for this information by entering their name, phone number, physical address or email. Whatever piece of information they have given you can be verified in one simple search.",
          open: false,
        },
        {
          question:
            "LIVE ADULT STREAMS OR XXX WEBSITES",
          answer:
            "<strong>XXX FLEX</strong> – Here also you have the option of purchasing individual or bundled search credits depending upon your needs. Learn if your partner is a consumer/subscriber to porn online. <br><br> <strong>XXX MONTHLY</strong> – These options run daily scans to monitor new activity and report notifications can be generated daily or whenever new activity is detected. Monthly plans have recurring billing. If you cancel the plan reports will continue to be generated until the end of that billing cycle but there will be no further charges.<br><br> <strong>XXX ULTIMATE</strong> – This package includes 15 search credits for use on each of our service areas. You get to choose when, and which service area to search on whomever you want. If your partner has multiple email addresses this option gives you a comprehensive look at their activity across the board.",
          open: false,
        },
        {
          question:
            "TINDER BLASTER",
          answer:
            "<strong>TINDER BLASTER BASIC</strong> – Enter the name, specific age, specific location you believe your partner may be using. A list of users matching those parameters. If you find your partner with an additional search credit you can request a PROFILE UPDATE that will provide you their <strong>Tinder ID</strong> and full profile details.<br><br> <strong>TINDER BLASTER EXTENDED</strong> – If the <strong>BASIC</strong> search is too narrow with an additional search credit you can provide an age range and broad location radius that sweeps a wider area of activity. Once you find your partner you can request a <strong>PROFILE UPDATE</strong> that will provide you their <strong>Tinder ID</strong> and full profile details.",
          open: false,
        },
      ],
    };
  },
  methods: {
    toggleOpen: function (index) {
      this.faqs = this.faqs.map((faq, i) => {
        if (index === i) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      });
    },
  },
};
